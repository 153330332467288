import { nunito_sans } from '@styles/fonts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    margin: 'auto',
    maxWidth: theme.maxWidth,
    minHeight: '50px',
    overflow: 'hidden',
    padding: theme.spacing(3, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 4),
    },
  },
  tagContainer: {
    width: '100%',
    maxWidth: theme.maxWidth,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  tagBtn: {
    color: 'black',
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: '16px',
    lineHeight: '21.82px',
    letterSpacing: '2%',
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.37)',
    marginRight: theme.spacing(2.5),
    cursor: 'pointer',
  },
  active: {
    background: 'black',
    color: 'white',
    border: '1px solid black',
  },
  linkBtn: {
    textDecoration: 'none',
  },
}))

export default useStyles
