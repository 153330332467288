import { makeStyles } from 'tss-react/mui'
import { pxToRem } from '@styles/function'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles<{ img: string }>()((theme, { img }) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(10, 17.5),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    marginBottom: theme.spacing(20),
  },
  partnersRootContainer: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0),
    },
  },
  bullyingContainer: {
    borderRadius: '20px',
    maxWidth: theme.maxWidth,
    height: pxToRem(511),
    margin: 'auto',
    padding: theme.spacing(12.5),
    backgroundImage: ` linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),url(${img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5.5),
      height: pxToRem(171),
    },
  },
  zenModeBullyingContainer: {
    background: 'none',
    border: '1px solid black',
  },
  bullyLeftContainer: {
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  bullyDate: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(12),
    fontWeight: 700,
    lineHeight: pxToRem(16.37),
    letterSpacing: '2%',
    color: 'white',
    marginBottom: theme.spacing(3),
  },
  zenTypo: {
    color: 'black',
  },
  bullyHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: pxToRem(48),
    lineHeight: pxToRem(54),
    textTransform: 'uppercase',
    color: 'white',
    marginBottom: theme.spacing(3),
  },

  hideOnSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideOnMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cardContentContainer: {
    padding: 0,
  },
  cardText: {
    width: '70%',
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(24),
    fontWeight: '800',
    lineHeight: pxToRem(29),
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: pxToRem(20),
  },
  cardDateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: pxToRem(12),
  },
  cardDate: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(12),
    fontWeight: '700',
    lineHeight: pxToRem(16),
    letterSpacing: '0.02em',
    textAlign: 'center',
    color: '#818181',
  },
  cardHeartIcon: {
    color: '#818181',
    width: pxToRem(20),
    height: pxToRem(17),
  },
  cardBtnContainer: {
    padding: 0,
  },
}))

export default useStyles
