import React, { useState } from 'react'
import Link from 'next/link'
import { Grid } from '@mui/material'
import { ButtonComponent } from 'src/components/shared/atoms'
import { useGetTopicsListQuery } from 'src/store/ApiCall/topicsAPI'
import { preprocessTopicsData } from 'src/utils'
import useStyles from './Style'

interface TagContainerProps {
  query?: string
  setTagId?: (id: number) => void
  setIsHash?: (bool: boolean) => void
}

const TagContainer: React.FC<TagContainerProps> = ({
  query,
  setTagId,
  setIsHash,
}: TagContainerProps) => {
  const { data } = useGetTopicsListQuery()
  const { classes } = useStyles()
  const [tagData, setTagData] = useState<{ content: string; link: string }[]>()
  const [current, setCurrent] = useState<string>(query ? query : 'all')

  React.useEffect(() => {
    if (query) setCurrent(query)
  }, [query])
  React.useEffect(() => {
    if (data) {
      setTagData(preprocessTopicsData(data.data))
    }
  }, [data])

  React.useEffect(() => {
    if (data && query) {
      const filteredData = data?.data.filter(
        (item) =>
          item.attributes.topic.replace(/\s/g, '-').trim().toLowerCase() ==
          query?.toLowerCase()
      )
      if (filteredData[0]?.id) {
        setTagId && setTagId(filteredData[0]?.id)
        setIsHash && setIsHash(false)
      } else {
        //// for hashtags
        const filteredData = data?.data
          .filter((item) => item.attributes.topic.includes('#'))
          .map((item) => ({
            ...item,
            topics: item.attributes.topic.substring(1),
          }))
        setTagId && setTagId(filteredData[0]?.id)
        setIsHash && setIsHash(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, data])

  return (
    <Grid className={classes.rootContainer}>
      <Grid container className={classes.tagContainer}>
        {tagData
          ? tagData.length &&
            tagData.map((curr, index) => {
              if (curr.content.toLowerCase() === 'all') {
                return
              }
              return (
                <Link
                  href={
                    curr.content.includes('#')
                      ? `/${curr.content.slice(1)}-Support-Hub`
                      : `/${curr.content.replace(/\s/g, '-') + '-Support-Hub'}`
                  }
                  key={index}
                  className={classes.linkBtn}
                >
                  <ButtonComponent
                    className={`${classes.tagBtn} ${
                      current.replace(/\s/g, '-').trim().toLowerCase() ==
                        (curr.content.includes('#')
                          ? curr.content
                              .slice(1)
                              .replace(/\s/g, '-')
                              .trim()
                              .toLowerCase()
                          : curr.content
                              .replace(/\s/g, '-')
                              .trim()
                              .toLowerCase()) && classes.active
                    }`}
                    text={curr.content}
                    type="text"
                    onClick={() => {
                      setCurrent(curr.content)
                    }}
                  />
                </Link>
              )
            })
          : null}
      </Grid>
    </Grid>
  )
}

export default TagContainer
