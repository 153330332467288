import React from 'react'
import Link from 'next/link'
import { Grid, Typography } from '@mui/material'
import useStyles from './Style'
import Image from 'next/image'
import Emoji from 'src/assets/svgs/campaigns/emojiHug.svg'

import { ButtonComponent } from '../../atoms'
import clsx from 'clsx'
import { ConfidentialSupport } from 'src/types/components/shared/atom'
// import { useRouter } from 'next/router'

interface confidentialSupportProps {
  className?: string
  content: ConfidentialSupport
  zenMode: boolean
}
const ConfidentialSupport: React.FC<confidentialSupportProps> = ({
  className,
  content,
  zenMode,
}) => {
  const { classes } = useStyles()
  const { heading, para, btnDetail } = content
  // const router = useRouter()

  return (
    <Grid className={classes.rootContainer}>
      <Grid
        container
        className={clsx(
          classes.confidentialContainer,
          className,
          zenMode && classes.zenModeBg
        )}
      >
        {!zenMode && <Grid className={classes.noiseBg}></Grid>}

        <Grid
          container
          item
          md={7}
          sm={12}
          sx={{
            justifyContent: { xs: 'center', md: 'flex-start' },
            textAlign: { xs: 'center', md: 'left' },
            order: { xs: 2, sm: 2, md: 1 },
            position: 'relative',
          }}
        >
          <Grid container>
            <Grid item sx={{ width: '100%' }}>
              <Typography
                variant="h3"
                className={clsx(
                  classes.confidentialHeading,
                  zenMode && classes.zenModeClr
                )}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                className={clsx(
                  classes.confidentialPara,
                  zenMode && classes.zenModeClr
                )}
              >
                {para}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                },
              }}
            >
              <Grid item className={classes.getSupportBtnContainer}>
                <Link
                  href={btnDetail[0].href ? btnDetail[0].href : '#'}
                  className={classes.supportLinkBtn}
                >
                  <ButtonComponent
                    type="text"
                    gradient
                    text={btnDetail[0].content}
                    className={classes.getSupportBtn}
                  />
                </Link>
              </Grid>
              <Grid
                item
                sx={{
                  display: { xs: 'none', sm: 'block', md: 'block' },
                }}
              >
                <Link
                  href={btnDetail[1].href ? btnDetail[1].href : '#'}
                  className={classes.supportLinkBtn}
                >
                  <ButtonComponent
                    type="text"
                    text={btnDetail[1].content}
                    className={classes.learnMoreBtn}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={5}
          justifyContent="center"
          alignItems="center"
          sm={12}
          sx={{ order: { xs: 1, sm: 1, md: 2 }, position: 'relative' }}
        >
          <Grid>
            {!zenMode && (
              <Image className={classes.EmojiImg} src={Emoji} alt="emoji" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConfidentialSupport
