import React from 'react'

interface Props {
  stroke?: string
  fill?: string
}
const HeartIcon: React.FC<Props> = ({ stroke, fill }) => {
  return (
    <svg
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill={fill ? fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0081 27.7505L17.0009 27.7505L16.9937 27.7505C16.9389 27.7509 16.8845 27.7403 16.8337 27.7196C16.7836 27.6991 16.7379 27.669 16.6992 27.631C16.6988 27.6306 16.6983 27.6301 16.6979 27.6297L3.75606 14.6712C3.7554 14.6706 3.75474 14.6699 3.75408 14.6692C2.36254 13.2621 1.58203 11.3629 1.58203 9.38385C1.58203 7.40521 2.36219 5.50643 3.75316 4.09939C5.15566 2.70239 7.0546 1.91797 9.03426 1.91797C11.0149 1.91797 12.9148 2.70319 14.3175 4.10152C14.3178 4.10178 14.318 4.10205 14.3183 4.10231L16.117 5.90107L17.0009 6.78495L17.8848 5.90107L19.6836 4.10231C19.6839 4.10202 19.6841 4.10173 19.6844 4.10144C21.0871 2.70316 22.987 1.91797 24.9676 1.91797C26.9472 1.91797 28.8461 2.70237 30.2486 4.09933C31.6396 5.50637 32.4198 7.40518 32.4198 9.38385C32.4198 11.363 31.6392 13.2623 30.2476 14.6695C30.247 14.67 30.2464 14.6706 30.2458 14.6712L17.304 27.6297C17.3036 27.6301 17.3032 27.6305 17.3027 27.6309C17.2641 27.669 17.2183 27.6991 17.1681 27.7196C17.1174 27.7403 17.063 27.7509 17.0081 27.7505Z"
        stroke={stroke}
        strokeWidth="2.5"
      />
    </svg>
  )
}

export default HeartIcon
