import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GreyHeart from 'src/assets/svgs/icons/greyHeart'
import HeartIcon from 'src/assets/svgs/icons/heart'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import useStyles from './Style'
import { FeaturedArticleProps } from 'src/types'
import Moment from 'react-moment'
import bullBackground from 'src/assets/images/articles/christopher.jpg'
import { useRouter } from 'next/router'
import {
  useDisLikeArticleMutation,
  useLikeArticleMutation,
  useUpdateArticlesMutation,
} from 'src/store/ApiCall/articleAPI'

interface Props {
  zenMode: boolean
  articleData: FeaturedArticleProps
  partnersFeature?: boolean
}

const FeaturedArticle: React.FC<Props> = ({
  zenMode = false,
  articleData,
  partnersFeature,
}) => {
  const { classes } = useStyles({
    img: articleData.image ? articleData.image : bullBackground.src,
  })
  const [like, setLike] = useState<boolean>(false)

  const [disLike] = useDisLikeArticleMutation()
  const [likeArticle] = useLikeArticleMutation()
  const [updateArticle] = useUpdateArticlesMutation()
  const [articleLikeCount, setArticleLikeCount] = useState<number>(
    articleData.article_like_count ? articleData.article_like_count : 0
  )

  useEffect(() => {
    if (articleData && articleData.article_like_count) {
      setArticleLikeCount(articleData.article_like_count)
    } else {
      setArticleLikeCount(0)
    }
  }, [articleData])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()
  const [runQuery, setRunQuery] = React.useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function updateArticleHandler(data: any) {
    const response = await updateArticle(data).unwrap()
    return response
  }

  React.useEffect(() => {
    if (runQuery) {
      updateArticleHandler({
        data: {
          id: articleData.id,
          article_like_count: articleLikeCount,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLikeCount])

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  React.useEffect(() => {
    if (articleData.isLiked) setLike(articleData.isLiked)
    else setLike(false)
  }, [articleData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function disLikeHandler(data: any) {
    const response = await disLike(data).unwrap()
    return response
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function likeArticleHandler(data: any) {
    const response = await likeArticle(data).unwrap()
    return response
  }

  const router = useRouter()
  const [article, setArticle] = React.useState<FeaturedArticleProps>()

  React.useEffect(() => {
    setArticle(articleData)
  }, [articleData])
  const heartIconHandler = async (event: React.SyntheticEvent) => {
    event.stopPropagation()
    if (!loggedInUser) {
      router.push(`/auth/login`)
      return
    }
    if (like) {
      // post request to delete record from article like where userId , articleId
      //after record setLike to false
      const response = await disLikeHandler({
        articleId: articleData.id,
        userId: loggedInUser,
      })

      if (response) {
        setRunQuery(true)
        setLike(false)
        const updatedCount = articleLikeCount - 1
        setArticleLikeCount(updatedCount)
      }
    } else {
      // add a post request to add record in article like tabble using userId ,article Id
      //after record setLike to true
      const response = await likeArticleHandler({
        data: {
          article: articleData.id,
          dtl_user: loggedInUser,
        },
      })

      if (response) {
        setRunQuery(true)
        setLike(true)
        const updatedCount = articleLikeCount + 1
        setArticleLikeCount(updatedCount)
      }
    }
  }

  if (article) {
    return (
      <Grid
        container
        className={`${classes.rootContainer} ${
          partnersFeature && classes.partnersRootContainer
        }`}
        onClick={() => {
          if (articleData.slug) {
            router.push(`/${articleData.slug}`)
          }
        }}
      >
        <Grid
          container
          className={`${classes.bullyingContainer} ${
            zenMode && classes.zenModeBullyingContainer
          }`}
        >
          <Grid
            item
            className={classes.bullyLeftContainer}
            container
            md={6}
            sm={12}
          >
            <Grid item>
              <Grid className={classes.hideOnSm}>
                <Typography
                  className={`${classes.bullyDate} ${
                    zenMode && classes.zenTypo
                  }`}
                >
                  <Moment format="MMMM DD, YYYY">{article.date}</Moment>
                </Typography>
                <Typography
                  variant="h3"
                  className={`${classes.bullyHeading} ${
                    zenMode && classes.zenTypo
                  }`}
                >
                  {article.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={6}
            justifyContent="flex-end"
            alignItems="flex-end"
            className={classes.hideOnSm}
          >
            <span onClick={heartIconHandler}>
              <HeartIcon
                fill={like ? 'black' : 'none'}
                stroke={zenMode || like ? 'red' : 'white'}
              />
            </span>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: { xs: 'block', sm: 'none', md: 'none' },
            width: '100%',
            padding: '20px 0',
            marginBottom: '0px',
          }}
        >
          <Grid className={classes.cardContentContainer}>
            <Grid className={classes.cardDateContainer}>
              <Moment format="MMMM DD, YYYY">{article.date}</Moment>
              <span
                className={classes.cardHeartIcon}
                onClick={heartIconHandler}
              >
                <GreyHeart fill={like ? 'black' : 'none'} />
              </span>
            </Grid>

            <Typography className={classes.cardText}>
              {article.title}
            </Typography>
          </Grid>
          <Grid className={classes.cardBtnContainer}>
            {article.tags
              ? article.tags.map((el: string, index: number) => (
                  <Tag
                    id={index}
                    key={index}
                    content={el}
                    color="black"
                    background="transparent"
                  />
                ))
              : ''}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <></>
}

export default FeaturedArticle
