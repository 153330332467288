import { Typography } from '@mui/material'
import React from 'react'
import useStyles from './Styles'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import Image from 'next/image'
import Icon1 from 'src/assets/images/userIcons/Ellipse188.png'
import Icon2 from 'src/assets/images/userIcons/Ellipse189.png'
import Icon3 from 'src/assets/images/userIcons/Ellipse190.png'
import Icon4 from 'src/assets/images/userIcons/Ellipse191.png'
import Icon5 from 'src/assets/images/userIcons/Ellipse192.png'
import { ButtonComponent } from 'src/components/shared/atoms'
import { MeetNewFriend } from 'src/types/components/shared/atom'
import Img from 'src/assets/images/dtlOriginals/newFriends.jpg'

// import { useRouter } from 'next/router'

interface Props {
  zenMode: boolean
  content: MeetNewFriend
}

function MeetNewFriend(props: Props) {
  const { zenMode, content } = props

  const { heading, para, btnDetail } = content

  const buttonHandler = (href: string) => {
    const userToken =
      typeof window === 'object' ? localStorage.getItem('dtl_token') : null
    if (userToken) {
      // TODO: make a DTLUser API call using userToken.id
      document.location.href = href
      return
    } else {
      // router.push('/auth/signup')
      document.location.href = href
    }
  }

  const { classes } = useStyles()

  return (
    <Box
      className={!zenMode ? classes.container : classes.containerZenMode}
      mb={0}
      sx={{ position: 'relative', overflow: 'hidden' }}
    >
      {!zenMode && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        >
          <Image src={Img?.src} alt={'background'} fill />
        </div>
      )}

      <Grid
        container
        py={{ md: 13, sm: 12, xs: 10 }}
        pb={{ md: 13, sm: 12, xs: 4 }}
      >
        <Grid
          item
          md={8}
          pl={{ md: 18, sm: 10, xs: 6 }}
          pr={{ lg: 18, md: 26, sm: 10, xs: 6 }}
        >
          {!zenMode && (
            <span className={classes.viewOnSm}>
              {' '}
              <Image src={Icon1} alt={''} />
              <Image src={Icon2} alt={''} />
              <Image src={Icon3} alt={''} />
              <Image src={Icon4} alt={''} />
              <Image src={Icon5} alt={''} />
            </span>
          )}
          <Typography className={classes.heading}>
            <span>{heading}</span>
            <br />
            {!zenMode && (
              <span className={classes.viewOnLg}>
                {' '}
                <Image src={Icon1} alt={''} />
                <Image src={Icon2} alt={''} />
                <Image src={Icon3} alt={''} />
                <Image src={Icon4} alt={''} />
                <Image src={Icon5} alt={''} />
              </span>
            )}
          </Typography>
          <Typography className={classes.text}>{para}</Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          pr={{ md: 18, sm: 10, xs: 6 }}
          pl={{ md: 0, sm: 10, xs: 6 }}
          pt={{ md: 0, sm: 10, xs: 6 }}
        >
          <div className={classes.btnContainer}>
            <ButtonComponent
              type="contained"
              text={btnDetail.content}
              className={classes.recommendBtn}
              wrapperClass={classes.btnWrapper}
              gradient
              onClick={() => buttonHandler(btnDetail.href)}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MeetNewFriend
