import React, { useState } from 'react'
import { Box, Container, InputAdornment, Grid, Typography } from '@mui/material'
import { RoundTextField } from 'src/components/shared/atoms'
import { SearchIcon } from 'src/assets/svgs'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Nunito_Sans } from '@next/font/google'
import { useRouter } from 'next/router'
import { useGetHeroHeaderQuery } from 'src/store/ApiCall/pagesAPI'
import { preprocessHeroHeaderData, getSpecificPartOfString } from 'src/utils'
import useStyles from './Style'
import HeroHeaderBg from 'src/components/shared/modules/lottiePlayer/heroHeaderBg'
import { getCookie } from 'cookies-next'
import Image from 'next/image'
import brp from 'src/assets/images/brp2d.png'

const nunito_sans = Nunito_Sans({
  weight: ['400', '700', '800'],
  subsets: ['latin'],
})
interface Props {
  tagId: number
  isHash: boolean
  tagName: string
  showBrp?: boolean
}

const HubHeroHeader = ({ tagId, tagName, isHash, showBrp }: Props) => {
  const { classes } = useStyles()
  const [dropDownOpen, setDropDownOpen] = useState('collapsed')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [hubHeroHeader, setHubHeroHeader] = useState<any>()
  const [defaultHub, setDefaultHub] = React.useState(false)
  const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')
  const [query, setQuery] = useState('all')
  const router = useRouter()
  const { tag } = router.query
  const { data: HeroHeaderData, isLoading } = useGetHeroHeaderQuery(
    tagId ? tagId : 'all'
  )
  const [age, setAge] = React.useState('10')
  const mainCategory = [
    'Bullying',
    'Mental Health',
    'Relationships',
    'Identity',
  ]
  React.useEffect(() => {
    if (!HeroHeaderData && !isLoading) {
      return
    } else {
      setHubHeroHeader(preprocessHeroHeaderData(HeroHeaderData, tagName))
      setDefaultHub(false)
    }

    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HeroHeaderData])

  React.useEffect(() => {
    if (tag) {
      if (typeof tag == 'string') {
        setQuery(tag)
      } else {
        setQuery(tag[0])
      }
    } else {
      setQuery('all')
    }
  }, [tag])

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string)
  }

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setQuery(e.target.value)
  }

  const enterKeyHandler = (e: React.KeyboardEvent) => {
    if (!query) return

    if (e.key === 'Enter') {
      router.push(`/search?search=${query}`)
      return
    }
    return
  }

  return (
    <>
      <HeroHeaderBg pageName="hub" />
      <Box className={`${classes.rootContainer} ${nunito_sans.className}`}>
        <Container className={classes.contentContainer} disableGutters>
          {!defaultHub && hubHeroHeader ? (
            <Grid container item md={12}>
              <Grid item md={12} xs={12}>
                <Typography variant="h3" className={classes.category}>
                  {isBrowser && lang === 'En'
                    ? `let's talk about...`
                    : 'HABLEMOS ACERCA DE LO QUE QUIERAS...'}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h1" className={classes.mainTitle}>
                  {hubHeroHeader.title}
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.subTitleContainer}>
                <Typography variant="body2" className={classes.subTitle}>
                  {hubHeroHeader.subTitle}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            tag &&
            typeof tag === 'string' &&
            !mainCategory.includes(
              getSpecificPartOfString(tag.replace(/-/g, ' '))
            ) && (
              <Grid container item md={12} sx={{ minHeight: '190px' }}>
                <Grid item md={12}>
                  <Typography variant="h3" className={classes.category}>
                    {isBrowser && lang === 'En'
                      ? `let's talk about...`
                      : 'HABLEMOS ACERCA DE LO QUE QUIERAS...'}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h1" className={classes.mainTitle}>
                    {tag && !isHash && typeof tag === 'string'
                      ? getSpecificPartOfString(
                          tag.replace(/-/g, ' ').toLowerCase()
                        )
                      : getSpecificPartOfString(
                          '#' + tag.replace(/-/g, ' ')
                        ).toLowerCase()}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
          {showBrp && getCookie('lang') !== 'En' ? (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: '10px' }}
              >
                <Typography variant="h6" sx={{ marginRight: '10px' }}>
                  IMPULSADO POR
                </Typography>
                {/* <BRPLogo fillColor="black" /> */}
                <Image src={brp} alt="brp" width="50" height="50" />
              </Grid>
            </>
          ) : (
            ''
          )}
          <Box className={classes.popularContainer}>
            <RoundTextField
              variant="outlined"
              fullWidth
              className={
                dropDownOpen === 'collapsed'
                  ? `${classes.signupField} ${classes.signupFieldCollapsed} `
                  : `${classes.signupField} ${classes.signupFieldExpanded}`
              }
              onChange={searchHandler}
              onKeyDown={enterKeyHandler}
              placeholder={
                isBrowser && lang === 'En'
                  ? 'Ask us anything...'
                  : 'Pregúntanos lo que quieras'
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box className={classes.inputAdornmentStart}>
                      <SearchIcon />
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      <Typography className={classes.aiIcon}>
                        {isBrowser && lang === 'En' ? (
                          <p>
                            <span>🤖</span> POWERED BY AI
                          </p>
                        ) : (
                          <p>
                            {' '}
                            <span>🤖</span> Utilizando IA
                          </p>
                        )}
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onOpen={() => setDropDownOpen('expanded')}
                        onClose={() => setDropDownOpen('collapsed')}
                        className={
                          dropDownOpen === 'expanded'
                            ? classes.dropDownBtn
                            : classes.dropDownClose
                        }
                        value={age}
                        label="Age"
                        onChange={handleChange}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value={10}>
                          {lang === 'En' ? 'All' : 'Todos'}
                        </MenuItem>
                        <MenuItem value={20}>
                          {lang === 'En' ? 'Article' : 'Artículos'}
                        </MenuItem>
                        <MenuItem value={30}>
                          {lang === 'En' ? 'Videos' : 'Videos'}
                        </MenuItem>
                        {lang === 'En' && (
                          <MenuItem value={40}>
                            {lang === 'En' ? 'Research papers' : ''}
                          </MenuItem>
                        )}
                        {lang === 'En' && (
                          <MenuItem value={50}>
                            {lang === 'En' ? 'Community Threads' : ''}
                          </MenuItem>
                        )}
                      </Select>
                    </>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default HubHeroHeader
