import { makeStyles } from 'tss-react/mui'
// import Img from 'src/assets/images/dtlOriginals/newFriends.jpg'
import { nunito_sans } from '@styles/fonts'
import { pxToRem } from '@styles/function'

const useStyles = makeStyles()((theme) => ({
  root: {},
  container: {
    margin: theme.spacing(32, 0),
    height: 'auto',

    // backgroundImage: `url(${Img?.src})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: pxToRem(70),
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(12, 0, 12),
      borderRadius: pxToRem(44),
    },
  },
  containerZenMode: {
    margin: theme.spacing(32, 0),
    height: 'auto',
    border: '1px solid black',
    backgroundRepeat: 'no-repeat',
    borderRadius: pxToRem(70),
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(12, 0, 12),
      borderRadius: pxToRem(44),
    },
  },
  heading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '800',
    fontSize: pxToRem(64),
    lineHeight: pxToRem(72),
    textTransform: 'uppercase',
    color: '#000000',
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      width: '100%',
      lineHeight: 'normal',
      textAlign: 'center',
    },

    span: {
      position: 'relative',
      top: '10px',
      img: {
        marginTop: '-1rem',
        paddingLeft: '.5rem',
      },
    },
  },
  viewOnLg: {
    img: {
      width: '58px',
      height: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  viewOnSm: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      img: {
        paddingLeft: '4px',
        width: '45px',
        height: '44px',
      },
    },
  },
  text: {
    width: '75%',
    ...theme.textPrimary,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    color: '#000000',
    opacity: '0.7',
    textAlign: 'start',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  btn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    color: theme.custom.lightFont,
    backgroundColor: theme.custom.blackColor,
    ':hover': {
      background: theme.custom.blackColor,
    },
  },

  btnContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  recommendBtn: {
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },
  btnWrapper: {
    width: 'auto !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
}))

export default useStyles
