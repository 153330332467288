import { pxToRem } from '@styles/function'
import { makeStyles } from 'tss-react/mui'
import mainImage from 'src/assets/images/confidential/conmain.jpg'
import noise from 'src/assets/images/campaigns/noise.png'
import { nunito_sans, lato } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    position: 'relative',
    padding: theme.spacing(10, 17.5, 15),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  confidentialContainer: {
    maxWidth: '1140px',
    margin: '0 auto',
    marginBottom: pxToRem(65),
    padding: `${pxToRem(49)} ${pxToRem(65)}`,
    backgroundImage: `url(${mainImage?.src})`,
    position: 'relative',
    borderRadius: '40px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(5, 4),
    },
  },
  noiseBg: {
    position: 'absolute',
    borderRadius: '40px',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${noise?.src})`,
  },
  EmojiImg: {
    width: '218px',
    height: '218px',
    [theme.breakpoints.down('sm')]: {
      width: '68px',
      height: '68px',
    },
  },
  confidentialHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: pxToRem(48),
    lineHeight: pxToRem(54),
    color: 'white',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(43.65),
      alignSelf: 'center',
    },
  },
  confidentialPara: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(19.2),
    color: 'white',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16.8),
    },
  },
  getSupportBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  supportLinkBtn: {
    textDecoration: 'none',
  },
  getSupportBtn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.82),
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(3.5, 6.5),
    background: '#1C1C1C',
    width: '100%',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
  },
  learnMoreBtn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.82),
    color: 'black',
    textAlign: 'center',
    padding: theme.spacing(3.5, 6.5),
    background: 'transparent',
    border: '1px solid black',
  },

  zenModeBg: {
    backgroundImage: 'none',
    border: '1px solid black',
  },
  zenModeClr: {
    color: '#000000',
  },
}))

export default useStyles
