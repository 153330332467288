import { makeStyles } from 'tss-react/mui'
import noise from 'src/assets/svgs/noise.svg'
const useStyles = makeStyles()((theme) => ({
  lightBrownBg: {
    background: 'rgba(242, 242, 242, 1)',
  },
  noiseBg: {
    backgroundImage: `url(${noise?.src})`,
    mixBlendMode: 'multiply',
  },
  meetNewFriendContainer: {
    padding: theme.spacing(0, 17.5, 0, 17.5),
    width: '100%',
    maxWidth: theme.maxWidth,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  searchArticleContainer: {
    width: '100%',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
  },
  searchArticleInner: {
    width: '100%',
    maxWidth: theme.maxWidth,
    padding: theme.spacing(0, 14),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  notAloneWrapper: {
    width: '100%',
    maxWidth: theme.maxWidth,
    margin: 'auto',
  },
}))

export default useStyles
